import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import React from "react";
import styled from 'styled-components';

const MastheadContainer = styled.div`
    padding-top: calc(4rem + 55px);
    padding-bottom: 4rem;
`;

const MastheadTitle = styled.h1`
    font-size: 5em;
    font-weight: bold;
`;

const Masthead = ({className}) => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "header.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
                }
            }
        `}
        render={data => {
            const imageData = data.desktop.childImageSharp.fluid
        return (
                <BackgroundImage
                    Tag="div"
                    id="masthead"
                    className="jumbotron jumbotron-fluid text-center text-white"
                    fluid={imageData}
                    >
                        <MastheadContainer className="container">
                            <MastheadTitle className="display-4">Bright-A-Bins</MastheadTitle>
                        </MastheadContainer>
                    
                </BackgroundImage>
            )
        }}
    />
)

export default Masthead
