import React from "react"

const ContactUs = () => (
    <section id="contact-us" className="services-section" >
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 order-lg-2">
                    <div className="p-5">
                        <form className="form-horizontal" name="contact" method="POST" netlify>
                            <div className="form-group row"><label className="col-md-3">Name:</label><input className="form-control col-md-8" type="text" name="name" /></div>
                            <div className="form-group row"><label className="col-md-3">Address:</label><input className="form-control col-md-8" type="text" name="address" /></div>
                            <div className="form-group row"><label className="col-md-3">Phone:</label><input className="form-control col-md-8" type="text" name="phone" /></div>
                            <div className="form-group row"><label className="col-md-3">Mobile:</label><input className="form-control col-md-8" type="text" name="mobile" /></div>
                            <div className="form-group row"><label className="col-md-3">Email:</label><input className="form-control col-md-8" type="text" name="email" /></div>
                            <div className="form-group row"><label className="col-md-3">My Bins:</label>
                                <div className="form-check"><input className="form-check-input" type="checkbox" id="formCheck-1" name="waste" /><label className="form-check-label" htmlFor="formCheck-1">Waste&nbsp;&nbsp;</label></div>
                                <div className="form-check"><input className="form-check-input" type="checkbox" id="formCheck-2" name="recyling" /><label className="form-check-label" htmlFor="formCheck-1">Recycling&nbsp;&nbsp;</label></div>
                                <div className="form-check"><input className="form-check-input" type="checkbox" id="formCheck-3" name="green-waste" /><label className="form-check-label" htmlFor="formCheck-1">Green Waste</label></div>
                            </div>
                            <div className="form-group row"><label className="col-md-3">Message:</label><textarea className="form-control" name="message" ></textarea></div>
                            <div className="form-group row"><div className="col-md-2 offset-md-10 text-center"><button className="btn btn-secondary" type="submit">Send</button></div></div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 order-lg-1">
                    <div className="p-5">
                        <h2 className="display-4">Contact Us</h2>
                        <p>If you are interested in out services please feel free to contact us by filling out the form to the right. We'll be in touch as soon as possible to with further details on how you can have a fresh clean bin each and every week!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ContactUs

