import * as React from 'react';

const Prices = () => (
    <div id="prices" className="highlight-clean" style={{ backgroundColor: `rgb(71,182,238)`}}>
        <div className="container">
            <div className="intro">
                <h2 className="text-center">Prices</h2>
                <p className="text-center">We think you'll find our prices smell like roses too...</p>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-6 offset-lg-4">
                <table className="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">Cleaning Schedule</th>
                            <th scope="col">Price (per bin)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>First Clean/Once Off Clean</td>
                            <td>$25.00</td>
                        </tr>
                        <tr>
                            <td>Weekly Clean</td>
                            <td>$8.00</td>
                        </tr>
                        <tr>
                            <td>Fortnightly Clean</td>
                            <td>$9.00</td>
                        </tr>
                        <tr>
                            <td>Monthly Clean</td>
                            <td>$10.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
)

export default Prices