import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutUs from "../components/about-us";
import Services from "../components/services";
import ContactUs from "../components/contact-us";
import Testamonials from "../components/testamonials";
import Prices from "../components/prices";

// import $ from 'jquery';
// import Popper from 'popper.js';

import "../scss/application.scss"
import Masthead from "../components/masthead"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Masthead />
    <AboutUs />
    <Services />
    <ContactUs />
    <Prices />
  </Layout>
)

export default IndexPage
