import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const AboutUs = () => (
    <StaticQuery
        query={graphql`
            query {
                desktop: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
                }
            }
        `}
        render={ data => {
            const imageData = data.desktop.childImageSharp.fluid
            return (
            <section id="about-us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="p-5">
                                <Img className="rounded-circle img-fluid" fluid={imageData} />
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="p-5">
                                <h2 className="display-4">Don't put up with the smell, give us a bell...</h2>
                                <p>Unsightly and offensive smelling bins don't have be the norm. Through our professional cleaning process we can rejuvinate your old bin and leave it smelling clean and fresh.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            )
            }
        }
    />
)

export default AboutUs