import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faDumpster, faListAlt, faClock, faSeedling, faTruck } from '@fortawesome/free-solid-svg-icons'

const Services = () => (
    <div id="services" className="features-blue">
        <div className="container">
            <div className="intro">
                <h2 className="text-center">Services</h2>
                <p className="text-center">We offer many types of cleaning services</p>
            </div>
            <div className="row features">
                <div className="col-sm-6 col-md-4 item"><FontAwesomeIcon icon={faTrashAlt} className="icon" />
                    <h3 className="name">Curbside Bin Cleaing</h3>
                    <p className="description">Our professional cleaners will leave your curbside bins looking (and smelling) like new.</p>
                </div>
                <div className="col-sm-6 col-md-4 item"><FontAwesomeIcon icon={faDumpster} className="icon" />
                    <h3 className="name">Commercial Bins</h3>
                    <p className="description">We also clean bins for commercial busineses.</p>
                </div>
                <div className="col-sm-6 col-md-4 item"><FontAwesomeIcon icon={faListAlt} className="icon" />
                    <h3 className="name">Flexible Schedule</h3>
                    <p className="description">We offer a range of different cleaning schedules from once off cleans to bi-monthly cleans.</p>
                </div>
                <div className="col-sm-6 col-md-4 item"><FontAwesomeIcon icon={faClock} className="icon" />
                    <h3 className="name">Timely</h3>
                    <p className="description">Need a clean in a hurry? Let us know and we can respond promptly and efficiently.</p>
                </div>
                <div className="col-sm-6 col-md-4 item"><FontAwesomeIcon icon={faSeedling} className="icon" />
                    <h3 className="name">Deordorising</h3>
                    <p className="description">We offer deodorising crystals to help keep those smells at bay.</p>
                </div>
                <div className="col-sm-6 col-md-4 item"><FontAwesomeIcon icon={faTruck} className="icon" />
                    <h3 className="name">Mobile</h3>
                    <p className="description">Our mobile cleaning units are fully self contained so we can clean anywhere.</p>
                </div>
            </div>
        </div>
    </div>
)

export default Services